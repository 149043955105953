<template>
  <div>
    <v-form
      ref="form"
      @submit.prevent="handleFormSubmit"
      style="padding: 0 25px"
    >
      <rs-text-field
        v-model="search"
        @input="filterMenuItems"
        placeholder="Menüde ara"
        class="menu-search-input"
        dark
      />
    </v-form>

    <ul class="menu-nav">
      <li
        v-if="isMenuItemVisible('mali_gelir_islemleri')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/incomes') }"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon mdi mdi-cash-plus"></i>
          <span class="menu-text">Gelir İşlemleri</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li
              aria-haspopup="true"
              class="menu-item menu-item-parent"
              v-bind:class="{ 'menu-item-open': hasActiveChildren('/incomes') }"
            >
              <span class="menu-link">
                <span class="menu-text">Gelir İşlemleri</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'incomes.houses.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('see-house-income') &&
                isMenuItemVisible('bagimsiz_bolum_mali_islemleri')
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-home-analytics"></i>
                  <span class="menu-text">Bağımsız Bölüm Mali İşlemleri</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'incomes.residents.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('see-resident-income') &&
                isMenuItemVisible('sakin_mali_islemleri')
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-home-account"></i>
                  <span class="menu-text">Sakin Mali İşlemleri</span>
                </a>
              </li>
            </router-link>

            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/incomes/bank'),
              }"
              v-if="
                can('see-bank-online') &&
                isMenuItemVisible('banka_online_islemleri')
              "
            >
              <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon mdi mdi-cash-fast"></i>
                <span class="menu-text">Banka Online İşlemleri</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text"></span>
                    </span>
                  </li>

                  <router-link
                    :to="{ name: 'incomes.bank.open.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('banka_online_bekleyen')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-cash-clock"
                          ><span></span
                        ></i>
                        <span class="menu-text">Banka Online Bekleyen</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'incomes.bank.closed.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('banka_islenen')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-bank-check"
                          ><span></span
                        ></i>
                        <span class="menu-text">Banka İşlenen</span>
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </li>

            <router-link
              :to="{ name: 'incomes.other.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('see-other-income') &&
                isMenuItemVisible('diger_gelir_islemleri')
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-cash-refund"></i>
                  <span class="menu-text">Diğer Gelir İşlemleri</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'incomes.risky.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('see-risky-resident') && isMenuItemVisible('riskli_sakin')
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-account-alert"></i>
                  <span class="menu-text">Riskli Sakin</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/expenses') }"
        v-if="
          isMenuItemVisible('mali_gider_islemleri') &&
          can('see-invoice') &&
          (hasRole('superadministrator') ||
            hasRole('companyadmin') ||
            hasRole('companyworker') ||
            hasRole('supervision') ||
            hasRole('local_cluster_manager'))
        "
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon mdi mdi-cash-minus"></i>
          <span class="menu-text">Gider İşlemleri</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li
              aria-haspopup="true"
              class="menu-item menu-item-parent"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/expenses'),
              }"
            >
              <span class="menu-link">
                <span class="menu-text">Gider İşlemleri</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'expenses.invoices.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="can('see-invoice') && isMenuItemVisible('faturalar')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-timeline-clock"></i>
                  <span class="menu-text">Faturalar</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'expenses.invoiceitems.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('see-invoice') && isMenuItemVisible('gider_fatura_tahakkuk')
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-progress-check"></i>
                  <span class="menu-text">Gider Fatura Tahakkuk</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'expenses.invoicepayments.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('see-invoice') && isMenuItemVisible('gider_fatura_odenmis')
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-cash-check"></i>
                  <span class="menu-text">Gider Fatura Ödenmiş</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/activities') }"
        v-if="
          isMenuItemVisible('hesap_hareketleri') &&
          can('see-balance-activity') &&
          (hasRole('superadministrator') ||
            hasRole('companyadmin') ||
            hasRole('companyworker') ||
            hasRole('supervision') ||
            hasRole('local_cluster_manager'))
        "
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon mdi mdi-cash-register"></i>
          <span class="menu-text">Hesap Hareketleri</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Hesap Hareketleri</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'activities.houses.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('bagimsiz_bolum_hesap_hareketleri')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-home-switch-outline"
                    ><span></span
                  ></i>
                  <span class="menu-text"
                    >Bağımsız Bölüm Hesap Hareketleri</span
                  >
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'activities.residents.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('sakin_hesap_hareketleri')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-account-convert"><span></span></i>
                  <span class="menu-text">Sakin Hesap Hareketleri</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'activities.banks.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('banka_hesap_hareketleri')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-bank"></i>
                  <span class="menu-text">Banka Hesap Hareketleri</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'activities.safes.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('kasa_hesap_hareketleri')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-safe"></i>
                  <span class="menu-text">Kasa Hesap Hareketleri</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'activities.providers.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('tedarikci_hesap_hareketleri')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-truck"></i>
                  <span class="menu-text">Tedarikçi Hesap Hareketleri</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'activities.cc-payments.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                isMenuItemVisible('robosay_pos_islemleri') &&
                can('see-cc-payment') &&
                ((cluster && cluster.has_credit_card_payments) ||
                  hasRole('superadministrator'))
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-credit-card"></i>
                  <span class="menu-text">RoboSAY POS İşlemleri</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/assessments') }"
        v-if="can('see-assessment') && isMenuItemVisible('tahakkuk_islemleri')"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon mdi mdi-receipt-text-check"></i>
          <span class="menu-text">Tahakkuk İşlemleri</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Tahakkuk İşlemleri</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'assessments.all.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('tum_tahakkuklar')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-layers-triple-outline"
                    ><span></span
                  ></i>
                  <span class="menu-text">Tüm Tahakkuklar</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'assessments.general.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('genel_tahakkuklar')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-marker-check"><span></span></i>
                  <span class="menu-text">Genel Tahakkuklar</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'assessments.free.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('serbest_tahakkuklar')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-earth-arrow-right"
                    ><span></span
                  ></i>
                  <span class="menu-text">Serbest Tahakkuklar</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'assessments.house-type.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('bagimsiz_bolum_tipi')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-cube"><span></span></i>
                  <span class="menu-text">Bağımsız Bölüm Tipi</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'assessments.parametric.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('parametrik_tahakkuklar')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-variable"><span></span></i>
                  <span class="menu-text">Parametrik Tahakkuklar</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'assessments.transfer-due' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('edit-house-income') && isMenuItemVisible('devir_alacak')
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-timeline-plus-outline"></i>
                  <span class="menu-text">Devir Alacak</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/enforcements') }"
        v-if="can('see-enforcement') && isMenuItemVisible('icra_islemleri')"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon mdi mdi-card-bulleted-settings-outline"></i>
          <span class="menu-text">İcra İşlemleri</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">İcra İşlemleri</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'enforcements.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('icralar')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-card-multiple-outline"
                    ><span></span
                  ></i>
                  <span class="menu-text">İcralar</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/communication') }"
        v-if="
          (can('see-notification-template') || can('see-visitor-log')) &&
          isMenuItemVisible('iletisim')
        "
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon mdi mdi-ear-hearing"></i>
          <span class="menu-text">İletişim</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>

          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">İletişim</span>
              </span>
            </li>

            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/notifications'),
              }"
              v-if="isMenuItemVisible('bildirimler_sms-mobil')"
            >
              <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon mdi mdi-message-text">
                  <span></span>
                </i>
                <span class="menu-text">Bildirimler<br />(SMS-Mobil)</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text"></span>
                    </span>
                  </li>

                  <router-link
                    :to="{ name: 'communication.batch-notifications.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-notification') &&
                      isMenuItemVisible('gonderilen_bildirimler_toplu')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-cellphone-wireless"></i>
                        <span class="menu-text"
                          >Gönderilen Bildirimler Toplu</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'communication.sended-notifications.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-notification') &&
                      isMenuItemVisible('gonderilen_bildirimler_bireysel')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-cellphone-dock"></i>
                        <span class="menu-text"
                          >Gönderilen Bildirimler Bireysel</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'communication.notification-templates.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-notification-template') &&
                      isMenuItemVisible('bildirim_sablonlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-cellphone-settings"></i>
                        <span class="menu-text"> Bildirim Şablonları </span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{
                      name: 'communication.system-notification-templates.list',
                    }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      hasRole('superadministrator') &&
                      isMenuItemVisible('sistem_bildirim_sablonlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-cellphone-link"></i>
                        <span class="menu-text"
                          >Sistem Bildirim Şablonları</span
                        >
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </li>

            <router-link
              :to="{ name: 'communication.reminders.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('hatirlaticilar')"
            >
              <!-- v-if="can('see-house-income')" -->
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-alarm-multiple"></i>
                  <span class="menu-text">Hatırlatıcılar</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'communication.breakdowns.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <!-- v-if="can('see-house-income')" -->
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
                v-if="can('see-breakdown') && isMenuItemVisible('arizalar')"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-hammer-wrench"></i>
                  <span class="menu-text">Arızalar</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'communication.suggestions.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <!-- v-if="can('see-house-income')" -->
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
                v-if="can('see-suggestion') && isMenuItemVisible('oneriler')"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-head-lightbulb-outline"></i>
                  <span class="menu-text">Öneriler</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'communication.complaints.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <!-- v-if="can('see-house-income')" -->
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
                v-if="can('see-complaint') && isMenuItemVisible('sikayetler')"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-clipboard-alert"></i>
                  <span class="menu-text">Şikayetler</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'communication.maintenances.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <!-- v-if="can('see-house-income')" -->
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
                v-if="can('see-maintenance') && isMenuItemVisible('bakim')"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-spray-bottle"></i>
                  <span class="menu-text">Bakım</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'communication.house-users.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('see-house-user') &&
                isMenuItemVisible('communication.house-users.list')
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-fireplace-off"></i>
                  <span class="menu-text">Tüm BB-Sakinler</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{
                name: 'communication.visitor-logs.list',
              }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('see-visitor-log') &&
                isMenuItemVisible('ziyaretci_kayitlari')
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-door"></i>
                  <span class="menu-text">Ziyaretçi Kayıtları</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{
                name: 'communication.meeting-attendants.list',
              }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('see-resident') &&
                isMenuItemVisible('communication.meeting-attendants.list')
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-stamper"></i>
                  <span class="menu-text">Hazirun Listesi</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/analysis') }"
        v-if="can('see-analyses') && isMenuItemVisible('analizler')"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon mdi mdi-ab-testing"></i>
          <span class="menu-text">Analizler</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Analizler</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'analysis.income-type-house-user' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('borclu_bb-sakin_analiz')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-toy-brick-search"></i>
                  <span class="menu-text">Borçlu BB-Sakin Analiz</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'analysis.monthly-house-user' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('aylik_bb-sakin_analiz')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-account-details"></i>
                  <span class="menu-text">Aylık BB-Sakin Analiz</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'analysis.expense-pivot.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('aylik_gider_fatura_analiz')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-calendar-search"></i>
                  <span class="menu-text">Aylık Gider Fatura Analiz</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'analysis.expense-payment-pivot.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('aylik_gider_odeme_analiz')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-calendar-star"></i>
                  <span class="menu-text">Aylık Gider Ödeme Analiz</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'analysis.financial-status' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('mali_durum_analiz')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-finance"></i>
                  <span class="menu-text">Mali Durum Analiz</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'analysis.assessments' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('tahakkuk_analiz')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-finance"></i>
                  <span class="menu-text">Tahakkuk Analiz</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/reports') }"
        v-if="can('see-reports') && isMenuItemVisible('raporlar')"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon mdi mdi-chart-scatter-plot"></i>
          <span class="menu-text">Raporlar</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Raporlar</span>
              </span>
            </li>

            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/reports/extracts'),
              }"
              v-if="isMenuItemVisible('ekstreler')"
            >
              <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon mdi mdi-forwardburger">
                  <span></span>
                </i>
                <span class="menu-text">Ekstreler</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text"></span>
                    </span>
                  </li>

                  <router-link
                    :to="{ name: 'reports.extracts.account-statement' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('1_bb-sakin_hesap_ekstre')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-chart-multiple"></i>
                        <span class="menu-text">1. BB-Sakin Hesap Ekstre</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{
                      name: 'reports.extracts.balance-activities.provider',
                    }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('2_tedarikci_hesap_ekstre')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-store-marker"></i>
                        <span class="menu-text">2. Tedarikçi Hesap Ekstre</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{
                      name: 'reports.extracts.balance-activities.bank-account',
                    }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('3_banka_hesap_ekstre')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-table"></i>
                        <span class="menu-text">3. Banka Hesap Ekstre</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'reports.extracts.balance-activities.safe' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('4_kasa_hesap_ekstre')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-archive-search-outline"></i>
                        <span class="menu-text">4. Kasa Hesap Ekstre</span>
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </li>

            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/reports/operating'),
              }"
              v-if="isMenuItemVisible('isletme_defterleri')"
            >
              <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon mdi mdi-store-settings">
                  <span></span>
                </i>
                <span class="menu-text">İşletme Defterleri</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text"></span>
                    </span>
                  </li>

                  <router-link
                    :to="{ name: 'reports.operating.income-expense' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('1_gelir_gider_odeme')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-chart-gantt"></i>
                        <span class="menu-text">1. Gelir Gider (Ödeme)</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    :to="{
                      name: 'reports.operating.accounting-income-expense',
                    }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('2_gelir-gider_fatura')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i
                          class="menu-icon mdi mdi-chart-areaspline-variant"
                        ></i>
                        <span class="menu-text">2. Gelir-Gider (Fatura)</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'reports.operating.income' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('3_gelir_rapor')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-arrow-collapse-right"></i>
                        <span class="menu-text">3. Gelir Rapor</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    :to="{ name: 'reports.operating.expense' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('4_gider_rapor')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-arrow-collapse-left"></i>
                        <span class="menu-text">4. Gider Rapor</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    :to="{ name: 'reports.operating.income-expense-review' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('5_gelir-gider_ozet')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-chart-bar-stacked"></i>
                        <span class="menu-text">5. Gelir-Gider (Özet)</span>
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </li>

            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/reports/review'),
              }"
              v-if="isMenuItemVisible('denetim_raporlari')"
            >
              <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon mdi mdi-account-hard-hat-outline">
                  <span></span>
                </i>
                <span class="menu-text">Denetim Raporları</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text"></span>
                    </span>
                  </li>

                  <router-link
                    :to="{ name: 'reports.review-reports.review' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('1_denetim_raporu')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-gesture-tap-box"></i>
                        <span class="menu-text">1. Denetim Raporu</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'reports.review-reports.review-detail' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('2_mizan_raporu')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-gesture-tap-box"></i>
                        <span class="menu-text">2. Mizan Raporu</span>
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </li>

            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/reports/cluster'),
              }"
              v-if="isMenuItemVisible('tya_pano_raporlari')"
            >
              <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon mdi mdi-folder-file">
                  <span></span>
                </i>
                <span class="menu-text">TYA Pano Raporları</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text"></span>
                    </span>
                  </li>
                  <router-link
                    :to="{ name: 'reports.cluster.audit' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('1_tya_pano_raporu')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-home-analytics"></i>
                        <span class="menu-text">1. TYA Pano Raporu</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'reports.cluster.operating-items' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('2_faaliyetler')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-format-list-numbered"></i>
                        <span class="menu-text">2. Faaliyetler</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'reports.cluster.operating' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('3_faaliyet_raporu')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-format-list-text"></i>
                        <span class="menu-text">3. Faaliyet Raporu</span>
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </li>

            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/reports/sums'),
              }"
              v-if="isMenuItemVisible('borc_alacak_toplamlari')"
            >
              <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon mdi mdi-waves-arrow-left">
                  <span></span>
                </i>
                <span class="menu-text">Borç - Alacak Toplamları</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text"></span>
                    </span>
                  </li>
                  <router-link
                    :to="{ name: 'reports.sums.expense-detail' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="isMenuItemVisible('1-gider_detay')"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-waves-arrow-left"></i>
                        <span class="menu-text">
                          1. Tedarikçi Borç - Alacak Toplamları
                        </span>
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/my-company') }"
        v-if="
          (hasRole('companyadmin') ||
            hasRole('superadministrator') ||
            hasRole('companyworker')) &&
          isMenuItemVisible('sirket_panelim')
        "
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon mdi mdi-city"></i>
          <span class="menu-text">Şirket Panelim</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Şirket Panelim</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'my-company.info' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('see-company-info') &&
                isMenuItemVisible('sirket_bilgileri') &&
                (hasRole('companyadmin') || hasRole('superadministrator'))
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-domain"></i>
                  <span class="menu-text">Şirket Bilgileri</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'my-company.workers.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('see-company-user') &&
                isMenuItemVisible('sirket_calisanlari') &&
                (hasRole('companyadmin') || hasRole('superadministrator'))
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-badge-account-horizontal"></i>
                  <span class="menu-text">Şirket Çalışanları</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'my-company.worker-positions.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('see-company-user') &&
                isMenuItemVisible('calisan_gorev_turleri') &&
                (hasRole('companyadmin') || hasRole('superadministrator'))
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-account-network"><span></span></i>
                  <span class="menu-text">Çalışan Görev Türleri</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'my-company.providers.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('edit-company') && isMenuItemVisible('sirket_tedarikcileri')
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-dump-truck"></i>
                  <span class="menu-text">Şirket Tedarikçileri</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/my-customer') }"
        v-if="
          (hasRole('companyadmin') ||
            hasRole('superadministrator') ||
            hasRole('local_cluster_manager') ||
            hasRole('companyworker')) &&
          isMenuItemVisible('sirket_panelim')
        "
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon mdi mdi-domain"></i>
          <span class="menu-text">Müşteri Panelim</span>
          <v-icon v-if="hasPaymentWarning" class="payment-warning" dark
            >mdi-exclamation</v-icon
          >
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Müşteri Panelim</span>
              </span>
            </li>

            <!-- <router-link
              :to="{ name: 'my-customer.info' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                can('see-company-info') &&
                isMenuItemVisible('sirket_bilgileri') &&
                (hasRole('companyadmin') || hasRole('superadministrator'))
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-domain"></i>
                  <span class="menu-text">Müşteri Bilgileri</span>
                </a>
              </li>
            </router-link> -->

            <router-link
              :to="{ name: 'my-customer.billing.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="
                hasRole('local_cluster_manager') ||
                hasRole('companyadmin') ||
                hasRole('superadministrator') ||
                hasRole('companyworker')
              "
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-cash-100"></i>
                  <span class="menu-text">Ödemeler</span>
                  <v-icon v-if="hasPaymentWarning" class="payment-warning" dark
                    >mdi-exclamation</v-icon
                  >
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/logs') }"
        v-if="
          (isMenuItemVisible('sistem_bilgisi') &&
            hasRole('superadministrator')) ||
          hasRole('companyadmin') ||
          hasRole('companyworker') ||
          hasRole('supervision') ||
          hasRole('local_cluster_manager')
        "
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon mdi mdi-information"></i>
          <span class="menu-text">Sistem Bilgisi</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Sistem Bilgisi</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'logs.action.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isMenuItemVisible('kullanici_hareketleri')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-account-reactivate"
                    ><span></span
                  ></i>
                  <span class="menu-text">Kullanıcı Hareketleri</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open':
            hasActiveChildren('/definitions') ||
            hasActiveChildren('/other-definitions'),
        }"
        v-if="
          (isMenuItemVisible('tanımlar') && hasRole('superadministrator')) ||
          hasRole('companyadmin') ||
          hasRole('companyworker') ||
          hasRole('supervision') ||
          hasRole('local_cluster_manager')
        "
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon mdi mdi-clipboard-list"></i>
          <span class="menu-text">Tanımlar</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Tanımlar</span>
              </span>
            </li>

            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/definitions'),
              }"
              v-if="isMenuItemVisible('temel_tanimlar')"
            >
              <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon mdi mdi-clipboard-file">
                  <span></span>
                </i>
                <span class="menu-text">Temel Tanımlar</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text"></span>
                    </span>
                  </li>

                  <router-link
                    :to="{ name: 'definitions.companies.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-company') &&
                      isMenuItemVisible('yonetim_sirketi_tanimlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-home-modern"></i>
                        <span class="menu-text">Yönetim Şirketi Tanımları</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'definitions.clusters.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-cluster') &&
                      isMenuItemVisible('toplu_yasam_alani_tanimlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-home-city-outline"></i>
                        <span class="menu-text"
                          >Toplu Yaşam Alanı Tanımları</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'definitions.houses.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-house') &&
                      isMenuItemVisible('bagimsiz_bolum_tanimlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-home-assistant"></i>
                        <span class="menu-text">Bağımsız Bölüm Tanımları</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'definitions.residents.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-resident') &&
                      isMenuItemVisible('sakin_ve_kullanici_tanimlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-account-badge"></i>
                        <span class="menu-text"
                          >Sakin ve Kullanıcı Tanımları</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'definitions.cluster-providers.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-cluster-provider') &&
                      isMenuItemVisible('tedarikci_tanimlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-mower"></i>
                        <span class="menu-text">Tedarikçi Tanımları</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'definitions.bank-accounts.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-bank-account') &&
                      isMenuItemVisible('banka_hesap_tanimlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-bank-plus"></i>
                        <span class="menu-text">Banka Hesap Tanımları</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'definitions.safes.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-safe') && isMenuItemVisible('kasa_tanimlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-wallet-outline"></i>
                        <span class="menu-text">Kasa Tanımları</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'definitions.periodical-assessments.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-periodical-income') &&
                      isMenuItemVisible('periyodik_borclandirma_tanimlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-cube-unfolded"></i>
                        <span class="menu-text"
                          >Periyodik Borçlandırma Tanımları</span
                        >
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </li>
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-if="
                (isMenuItemVisible('diger_tanimlar') &&
                  hasRole('superadministrator')) ||
                hasRole('companyadmin') ||
                hasRole('companyworker') ||
                hasRole('supervision') ||
                hasRole('local_cluster_manager')
              "
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/other-definitions'),
              }"
            >
              <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon mdi mdi-clipboard-flow">
                  <span></span>
                </i>
                <span class="menu-text">Diğer Tanımlar</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text"></span>
                    </span>
                  </li>

                  <router-link
                    :to="{ name: 'other-definitions.expense-types.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-expense-type') &&
                      isMenuItemVisible('gider_turleri')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-tag-arrow-up"
                          ><span></span
                        ></i>
                        <span class="menu-text">Gider Türleri</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'other-definitions.child-expense-types.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-expense-type') &&
                      isMenuItemVisible('gider_tipleri')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-clipboard-arrow-up"
                          ><span></span
                        ></i>
                        <span class="menu-text">Gider Tipleri</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'other-definitions.income-types.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-income-type') &&
                      isMenuItemVisible('gelir_turleri')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-tag-arrow-down"
                          ><span></span
                        ></i>
                        <span class="menu-text">Gelir Türleri</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'other-definitions.other-incomes.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-other-income-type') &&
                      isMenuItemVisible('diger_gelir_tanimlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-cash-100"><span></span></i>
                        <span class="menu-text">Diğer Gelir Tanımları</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'other-definitions.housing-income.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-custom-income-type') &&
                      isMenuItemVisible('bagimsiz_bolum_ozel_gelir_turleri')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-home-group-plus"
                          ><span></span
                        ></i>
                        <span class="menu-text"
                          >Bağımsız Bölüm Özel Gelir Türleri</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'other-definitions.projects.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-project') && isMenuItemVisible('proje_tanimlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-home-roof"><span></span></i>
                        <span class="menu-text">Proje Tanımları</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{
                      name: 'other-definitions.fixtures.fixture-groups.list',
                    }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-fixture-group') &&
                      isMenuItemVisible('demirbas_gruplari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-sitemap"><span></span></i>
                        <span class="menu-text">Demirbaş Grupları</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    :to="{ name: 'other-definitions.fixtures.fixtures.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-fixture') && isMenuItemVisible('demirbaslar')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-folder-star-multiple"
                          ><span></span
                        ></i>
                        <span class="menu-text">Demirbaşlar</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    :to="{ name: 'other-definitions.payback-reasons.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-payback-reason') &&
                      isMenuItemVisible('avans_iade_nedenleri')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-cash-refund"
                          ><span></span
                        ></i>
                        <span class="menu-text">Avans İade Nedenleri</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    :to="{
                      name: 'other-definitions.types.contract-types.list',
                    }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-contract-type') &&
                      isMenuItemVisible('sozlesme_tur_tanimlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-format-list-text"
                          ><span></span
                        ></i>
                        <span class="menu-text">Sözleşme Tür Tanımları</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    :to="{
                      name: 'other-definitions.types.maintenance-types.list',
                    }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-control-type') &&
                      isMenuItemVisible('bakim_tur_tanimlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-hexagon-multiple-outline"
                          ><span></span
                        ></i>
                        <span class="menu-text">Bakım Tür Tanımları</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{
                      name: 'other-definitions.maintenance.list',
                    }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-control-type') &&
                      isMenuItemVisible('bakim_tanimlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-spray-bottle"
                          ><span></span
                        ></i>
                        <span class="menu-text">Bakım Tanımları</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'other-definitions.cluster-phones.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-cluster-phone') &&
                      isMenuItemVisible('onemli_telefonlar')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-cellphone-information"
                          ><span></span
                        ></i>
                        <span class="menu-text">Telefon Rehberi</span>
                      </a>
                    </li>
                  </router-link>
                  <!-- <router-link
                  :to="{ name: 'other-definitions.types.cleaning-types.list' }"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-if="can('see-cleaning-type') && isMenuItemVisible('temizlik_tur_tanimlari')"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon mdi mdi-faucet"><span></span></i>
                      <span class="menu-text">Temizlik Tür Tanımları</span>
                    </a>
                  </li>
                </router-link> -->
                  <router-link
                    :to="{
                      name: 'other-definitions.types.breakdown-types.list',
                    }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('see-breakdown-type') &&
                      isMenuItemVisible('ariza_tur_tanimlari')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-toolbox-outline"
                          ><span></span
                        ></i>
                        <span class="menu-text">Arıza Tür Tanımları</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    :to="{
                      name: 'other-definitions.house-integrations.list',
                    }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                      can('edit-house') && isMenuItemVisible('bb_entegrasyon')
                    "
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-connection"
                          ><span></span
                        ></i>
                        <span class="menu-text">DAF Entegrasyon</span>
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/budget'),
        }"
        v-if="
          isMenuItemVisible('budget') &&
          can('see-budget') &&
          cluster &&
          cluster.has_budgets
        "
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon mdi mdi-library">
            <span></span>
          </i>
          <span class="menu-text">Bütçe Yönetimi</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text"></span>
              </span>
            </li>
            <router-link
              :to="{ name: 'budget.definition.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-bind:class="{
                'menu-item-open':
                  hasActiveChildren('/budget/definition') ||
                  hasActiveChildren('/budget/wizard'),
              }"
              v-if="isMenuItemVisible('budget_definition')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-script"></i>
                  <span class="menu-text">Bütçe Tanımı</span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="{ name: 'budget.invoice-items.active' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-bind:class="{
                'menu-item-open': hasActiveChildren(
                  '/budget/invoice-items/active'
                ),
              }"
              v-if="isMenuItemVisible('budget_invoice_items_active')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-cash-check"></i>
                  <span class="menu-text">Bütçelenmiş Giderler</span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="{ name: 'budget.invoice-items.open' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-bind:class="{
                'menu-item-open': hasActiveChildren(
                  '/budget/invoice-items/open'
                ),
              }"
              v-if="isMenuItemVisible('budget_invoice_items_open')"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-cash-remove"></i>
                  <span class="menu-text">Açık Bütçe Giderleri</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/admin'),
        }"
        v-if="hasRole('superadministrator')"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon mdi mdi-shield-crown-outline">
            <span></span>
          </i>
          <span class="menu-text">Yönetici</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text"></span>
              </span>
            </li>

            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/admin/crm'),
              }"
            >
              <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon mdi mdi-face-agent">
                  <span></span>
                </i>
                <span class="menu-text">Müşteri İlişkileri</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text"></span>
                    </span>
                  </li>

                  <router-link
                    :to="{ name: 'admin.crm.customers.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-bind:class="{
                      'menu-item-open': hasActiveChildren(
                        '/admin/crm/customers'
                      ),
                    }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i
                          class="menu-icon mdi mdi-office-building-cog-outline"
                        ></i>
                        <span class="menu-text">Müşteriler</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    :to="{ name: 'admin.crm.customer-groups.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-bind:class="{
                      'menu-item-open': hasActiveChildren(
                        '/admin/crm/customer-groups'
                      ),
                    }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-group"></i>
                        <span class="menu-text">Müşteri Grupları</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    :to="{ name: 'admin.crm.customer-invoices.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-bind:class="{
                      'menu-item-open': hasActiveChildren(
                        '/admin/crm/customer-invoices'
                      ),
                    }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-invoice-outline"></i>
                        <span class="menu-text">Faturalar</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    :to="{ name: 'admin.crm.customer-payments.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-bind:class="{
                      'menu-item-open': hasActiveChildren(
                        '/admin/crm/customer-payments'
                      ),
                    }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-cash-100"></i>
                        <span class="menu-text">Ödemeler</span>
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    :to="{ name: 'admin.crm.bank-activities.list' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-bind:class="{
                      'menu-item-open': hasActiveChildren(
                        '/admin/crm/bank-activities'
                      ),
                    }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-bank-circle-outline"></i>
                        <span class="menu-text">Banka Hareketleri</span>
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </li>
            <router-link
              :to="{ name: 'admin.products.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/admin/products'),
              }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-package-variant"></i>
                  <span class="menu-text">Ürünler</span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="{ name: 'admin.users.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/admin/users'),
              }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-account-search"></i>
                  <span class="menu-text">Kullanıcılar</span>
                </a>
              </li>
            </router-link>

            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/admin/statistics'),
              }"
            >
              <a href="#" class="menu-link menu-toggle">
                <i class="menu-icon mdi mdi-chart-bell-curve">
                  <span></span>
                </i>
                <span class="menu-text">İstatistikler</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text"></span>
                    </span>
                  </li>

                  <router-link
                    :to="{ name: 'admin.statistics.web' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-bind:class="{
                      'menu-item-open': hasActiveChildren(
                        '/admin/statistics/web'
                      ),
                    }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-desktop-classic"></i>
                        <span class="menu-text">Web İstatistikler</span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    :to="{ name: 'admin.statistics.mobile' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-bind:class="{
                      'menu-item-open': hasActiveChildren(
                        '/admin/statistics/mobile'
                      ),
                    }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon mdi mdi-tablet-cellphone"></i>
                        <span class="menu-text">Mobil İstatistikler</span>
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </li>

            <router-link
              :to="{ name: 'admin.payout-check.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/admin/payout-check'),
              }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-cash-check"></i>
                  <span class="menu-text">Transfer Takip</span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="{ name: 'admin.ads.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/admin/ads'),
              }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-advertisements"></i>
                  <span class="menu-text">Reklamlar</span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="{ name: 'admin.import.transfers' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/admin/import/transfers'),
              }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon mdi mdi-timeline-plus-outline"></i>
                  <span class="menu-text">Devir</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <!-- <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/profile'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon far fa-user"></i>
        <span class="menu-text">Profile</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/profile/profile-1"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Profile 1</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/profile/profile-2"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Profile 2</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/profile/profile-3"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Profile 3</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/profile/profile-4"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Profile 4</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li> -->
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { hasPermissions } from "@/view/mixins";

export default {
  name: "KTMenu",
  mixins: [hasPermissions],
  data() {
    return {
      search: "",
      menuItems: [
        {
          name: "Gelir İşlemleri",
          url: "mali_gelir_islemleri",
          visible: true,
          searchable: true,
          keywords: "",
          subMenuItems: [
            {
              name: "Bağımsız Bölüm Mali İşlemleri",
              url: "bagimsiz_bolum_mali_islemleri",
              visible: true,
              searchable: true,
              keywords: "ev daire dükkan blok",
            },
            {
              name: "Sakin Mali İşlemleri",
              url: "sakin_mali_islemleri",
              visible: true,
              searchable: true,
              keywords: "nakit malik kiracı eski ev sahibi",
            },
            {
              name: "Banka Online İşlemleri",
              url: "banka_online_islemleri",
              visible: true,
              searchable: true,
              keywords: "para nakit kredi kartı",
              subMenuItems: [
                {
                  name: "Banka Online Bekleyen",
                  url: "banka_online_bekleyen",
                  visible: true,
                  searchable: true,
                  keywords: "para nakit kredi kartı",
                },
                {
                  name: "Banka İşlenen",
                  url: "banka_islenen",
                  visible: true,
                  searchable: true,
                  keywords: "para nakit kredi kartı",
                },
              ],
            },
            {
              name: "Diğer Gelir İşlemleri",
              url: "diger_gelir_islemleri",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Riskli Sakin",
              url: "riskli_sakin",
              visible: true,
              searchable: true,
              keywords: "",
            },
          ],
        },

        {
          name: "Gider İşlemleri",
          url: "mali_gider_islemleri",
          visible: true,
          searchable: true,
          keywords: "",
          subMenuItems: [
            {
              name: "Faturalar",
              url: "faturalar",
              visible: true,
              searchable: true,
              keywords: "harcama fatura gider ödeme tedarikçi",
            },
            {
              name: "Gider Fatura Tahakkuk",
              url: "gider_fatura_tahakkuk",
              visible: true,
              searchable: true,
              keywords: "harcama",
            },
            {
              name: "Gider Fatura Ödenmiş",
              url: "gider_fatura_odenmis",
              visible: true,
              searchable: true,
              keywords: "harcama",
            },
          ],
        },
        {
          name: "Hesap Hareketleri",
          url: "hesap_hareketleri",
          visible: true,
          searchable: true,
          keywords: "",
          subMenuItems: [
            {
              name: "Bağımsız Bölüm Hesap Hareketleri",
              url: "bagimsiz_bolum_hesap_hareketleri",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Sakin Hesap Hareketleri",
              url: "sakin_hesap_hareketleri",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Banka Hesap Hareketleri",
              url: "banka_hesap_hareketleri",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Kasa Hesap Hareketleri",
              url: "kasa_hesap_hareketleri",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Tedarikçi Hesap Hareketleri",
              url: "tedarikci_hesap_hareketleri",
              visible: true,
              searchable: true,
              keywords: "",
            },
          ],
        },

        {
          name: "RoboSAY POS İşlemleri",
          url: "robosay_pos_islemleri",
          visible: true,
          searchable: true,
          keywords: "sanal kredi kartı",
        },
        {
          name: "Tahakkuk İşlemleri",
          url: "tahakkuk_islemleri",
          visible: true,
          searchable: true,
          keywords: "borçlandırma",
          subMenuItems: [
            {
              name: "Tüm Tahakkuklar",
              url: "tum_tahakkuklar",
              visible: true,
              searchable: true,
              keywords: "borçlandırma",
            },
            {
              name: "Genel Tahakkuklar",
              url: "genel_tahakkuklar",
              visible: true,
              searchable: true,
              keywords: "borçlandırma",
            },
            {
              name: "Serbest Tahakkuklar",
              url: "serbest_tahakkuklar",
              visible: true,
              searchable: true,
              keywords: "borçlandırma",
            },
            {
              name: "Bağımsız Bölüm Tipi",
              url: "bagimsiz_bolum_tipi",
              visible: true,
              searchable: true,
              keywords: "borçlandırma",
            },
            {
              name: "Parametrik Tahakkuklar",
              url: "parametrik_tahakkuklar",
              visible: true,
              searchable: true,
              keywords:
                "borçlandırma tahakkuk parametre parametrik eşit arsa payı metrekare",
            },
            {
              name: "Devir Alacak",
              url: "devir_alacak",
              visible: true,
              searchable: true,
              keywords: "",
            },
          ],
        },

        {
          name: "İcra İşlemleri",
          url: "icra_islemleri",
          visible: true,
          searchable: true,
          keywords: "",
          subMenuItems: [
            {
              name: "İcralar",
              url: "icralar",
              visible: true,
              searchable: true,
              keywords: "",
            },
          ],
        },
        {
          name: "İletişim",
          url: "iletisim",
          visible: true,
          searchable: true,
          keywords: "",
          subMenuItems: [
            {
              name: "Bildirimler (SMS-Mobil)",
              url: "bildirimler_sms-mobil",
              visible: true,
              searchable: true,
              keywords: "cep telefon sms mobil iphone android",
              subMenuItems: [
                {
                  name: "Gönderilen Bildirimler Toplu",
                  url: "gonderilen_bildirimler_toplu",
                  visible: true,
                  searchable: true,
                  keywords: "cep telefon sms mobil iphone android",
                },
                {
                  name: "Gönderilen Bildirimler Bireysel",
                  url: "gonderilen_bildirimler_bireysel",
                  visible: true,
                  searchable: true,
                  keywords: "cep telefon sms mobil iphone android",
                },
                {
                  name: "Bildirim Şablonları",
                  url: "bildirim_sablonlari",
                  visible: true,
                  searchable: true,
                  keywords: "cep telefon sms mobil iphone android",
                },
                {
                  name: "Sistem Bildirim Şablonları",
                  url: "sistem_bildirim_sablonlari",
                  visible: true,
                  searchable: true,
                  keywords: "cep telefon sms mobil iphone android",
                },
              ],
            },
            {
              name: "Hatırlatıcılar",
              url: "hatirlaticilar",
              visible: true,
              searchable: true,
              keywords: "takvim",
            },
            {
              name: "Arızalar",
              url: "arizalar",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Öneriler",
              url: "oneriler",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Şikayetler",
              url: "sikayetler",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Bakım",
              url: "bakim",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Tüm BB-Sakinler",
              url: "communication.house-users.list",
              visible: true,
              searchable: true,
              keywords: "bb bağımsız bölüm bb liste",
            },
            {
              name: "Ziyaretçi Kayıtları",
              url: "ziyaretci_kayitlari",
              visible: true,
              searchable: true,
              keywords: "ziyaretçi kayıt kayıtları kapı giriş çıkış",
            },
            {
              name: "Hazirun Listesi",
              url: "communication.meeting-attendants.list",
              visible: true,
              searchable: true,
              keywords: "hazirun toplantı toplanti",
            },
          ],
        },
        {
          name: "Analizler",
          url: "analizler",
          visible: true,
          searchable: true,
          keywords: "",
          subMenuItems: [
            {
              name: "Borçlu BB-Sakin Analiz",
              url: "borclu_bb-sakin_analiz",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Aylık BB-Sakin Analiz",
              url: "aylik_bb-sakin_analiz",
              visible: true,
              searchable: true,
              keywords: "aylik analiz gelir",
            },
            {
              name: "Aylık Gider Fatura Analiz",
              url: "aylik_gider_fatura_analiz",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Aylık Gider Ödeme Analiz",
              url: "aylik_gider_odeme_analiz",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Mali Durum Analiz",
              url: "mali_durum_analiz",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Tahakkuk Analiz",
              url: "tahakkuk_analiz",
              visible: true,
              searchable: true,
              keywords: "tahakkuk analiz rapor",
            },
          ],
        },

        {
          name: "Raporlar",
          url: "raporlar",
          visible: true,
          searchable: true,
          keywords: "",
          subMenuItems: [
            {
              name: "Ekstreler",
              url: "ekstreler",
              visible: true,
              searchable: true,
              keywords: "",
              subMenuItems: [
                {
                  name: "1. BB-Sakin Hesap Ekstre",
                  url: "1_bb-sakin_hesap_ekstre",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "2. Tedarikçi Hesap Ekstre",
                  url: "2_tedarikci_hesap_ekstre",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "3. Banka Hesap Ekstre",
                  url: "3_banka_hesap_ekstre",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "4. Kasa Hesap Ekstre",
                  url: "4_kasa_hesap_ekstre",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
              ],
            },
            {
              name: "İşletme Defterleri",
              url: "isletme_defterleri",
              visible: true,
              searchable: true,
              keywords: "",
              subMenuItems: [
                {
                  name: "1. Gelir Gider (Ödeme)",
                  url: "1_gelir_gider_odeme",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "2. Gelir-Gider (Fatura)",
                  url: "2_gelir-gider_fatura",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "3. Gelir Rapor",
                  url: "3_gelir_rapor",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "4. Gider Rapor",
                  url: "4_gider_rapor",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "5. Gelir-Gider (Özet)",
                  url: "5_gelir-gider_ozet",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
              ],
            },
            {
              name: "Denetim Raporları",
              url: "denetim_raporlari",
              visible: true,
              searchable: true,
              keywords: "",
              subMenuItems: [
                {
                  name: "1. Denetim Raporu",
                  url: "1_denetim_raporu",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "2. Mizan Raporu",
                  url: "2_mizan_raporu",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
              ],
            },
            {
              name: "TYA Pano Raporları",
              url: "tya_pano_raporlari",
              visible: true,
              searchable: true,
              keywords: "",
              subMenuItems: [
                {
                  name: "1. TYA Pano Raporu",
                  url: "1_tya_pano_raporu",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "2. Faaliyetler",
                  url: "2_faaliyetler",
                  visible: true,
                  searchable: true,
                  keywords: "faaliyet rapor raporu faaliyetler",
                },
                {
                  name: "3. Faaliyet Raporu",
                  url: "3_faaliyet_raporu",
                  visible: true,
                  searchable: true,
                  keywords: "faaliyet rapor raporu faaliyetler",
                },
              ],
            },
            {
              name: "Borç - Alacak Toplamları",
              url: "borc_alacak_toplamlari",
              visible: true,
              searchable: true,
              keywords: "",
              subMenuItems: [
                {
                  name: "1. Tedarikçi Borç - Alacak Toplamları",
                  url: "1-gider_detay",
                  visible: true,
                  searchable: true,
                  keywords: "rapor gider tedarikçi borç alacak",
                },
              ],
            },
          ],
        },

        {
          name: "Şirket Panelim",
          url: "sirket_panelim",
          visible: true,
          searchable: true,
          keywords: "",
          subMenuItems: [
            {
              name: "Şirket Bilgileri",
              url: "sirket_bilgileri",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Şirket Çalışanları",
              url: "sirket_calisanlari",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Çalışan Görev Türleri",
              url: "calisan_gorev_turleri",
              visible: true,
              searchable: true,
              keywords: "",
            },
            {
              name: "Şirket Tedarikçileri",
              url: "sirket_tedarikcileri",
              visible: true,
              searchable: true,
              keywords: "şirket tedarik tedarikçi tedarikçiler",
            },
          ],
        },
        {
          name: "Sistem Bilgisi",
          url: "sistem_bilgisi",
          visible: true,
          searchable: true,
          keywords: "",
          subMenuItems: [
            {
              name: "Kullanıcı Hareketleri",
              url: "kullanici_hareketleri",
              visible: true,
              searchable: true,
              keywords: "",
            },
          ],
        },
        {
          name: "Tanımlar",
          url: "tanımlar",
          visible: true,
          searchable: true,
          keywords: "",
          subMenuItems: [
            {
              name: "Temel Tanımlar",
              url: "temel_tanimlar",
              visible: true,
              searchable: true,
              keywords: "",
              subMenuItems: [
                {
                  name: "Yönetim Şirketi Tanımları",
                  url: "yonetim_sirketi_tanimlari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Toplu Yaşam Alanı Tanımları",
                  url: "toplu_yasam_alani_tanimlari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Bağımsız Bölüm Tanımları",
                  url: "bagimsiz_bolum_tanimlari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Sakin ve Kullanıcı Tanımları",
                  url: "sakin_ve_kullanici_tanimlari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Tedarikçi Tanımları",
                  url: "tedarikci_tanimlari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Banka Hesap Tanımları",
                  url: "banka_hesap_tanimlari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Kasa Tanımları",
                  url: "kasa_tanimlari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Periyodik Borçlandırma Tanımları",
                  url: "periyodik_borclandirma_tanimlari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
              ],
            },
            {
              name: "Diğer Tanımlar",
              url: "diger_tanimlar",
              visible: true,
              searchable: true,
              keywords: "",
              subMenuItems: [
                {
                  name: "Gider Türleri",
                  url: "gider_turleri",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Gider Tipleri",
                  url: "gider_tipleri",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Gelir Türleri",
                  url: "gelir_turleri",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Diğer Gelir Tanımları",
                  url: "diger_gelir_tanimlari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Bağımsız Bölüm Özel Gelir Türleri",
                  url: "bagimsiz_bolum_ozel_gelir_turleri",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Proje Tanımları",
                  url: "proje_tanimlari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Demirbaş Grupları",
                  url: "demirbas_gruplari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Demirbaşlar",
                  url: "demirbaslar",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Avans İade Nedenleri",
                  url: "avans_iade_nedenleri",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Sözleşme Tür Tanımları",
                  url: "sozlesme_tur_tanimlari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Bakım Tür Tanımları",
                  url: "bakim_tur_tanimlari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Bakım Tanımları",
                  url: "bakim_tanimlari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "Telefon Rehberi",
                  url: "onemli_telefonlar",
                  visible: true,
                  searchable: true,
                  keywords: "telefon rehber",
                },
                {
                  name: "Arıza Tür Tanımları",
                  url: "ariza_tur_tanimlari",
                  visible: true,
                  searchable: true,
                  keywords: "",
                },
                {
                  name: "DAF Entegrasyon",
                  url: "bb_entegrasyon",
                  visible: true,
                  searchable: true,
                  keywords: "entegrasyon daf enerji",
                },
              ],
            },
          ],
        },

        {
          name: "Bütçe",
          url: "budget",
          visible: true,
          searchable: true,
          keywords: "bütçe",
          subMenuItems: [
            {
              name: "Bütçe Tanımı",
              url: "budget_definition",
              visible: true,
              searchable: true,
              keywords: "bütçe tanımı",
            },
            {
              name: "Bütçelenmiş Giderler",
              url: "budget_invoice_items_active",
              visible: true,
              searchable: true,
              keywords: "bütçe gider fatura kalem bütçelenmiş",
            },
            {
              name: "Açık Bütçe Giderleri",
              url: "budget_invoice_items_open",
              visible: true,
              searchable: true,
              keywords: "bütçe gider fatura kalem açık bütçelenmemiş",
            },
          ],
        },
      ],
      filteredMenuItems: [],
    };
  },
  computed: {
    ...mapGetters(["cluster", "hasPaymentWarning"]),
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

    turkishToLowercase(text) {
      const turkishLowerMap = {
        İ: "i",
        I: "ı",
        Ş: "ş",
        Ğ: "ğ",
        Ü: "ü",
        Ö: "ö",
        Ç: "ç",
      };

      text = text.replace(/İ|I|Ş|Ğ|Ü|Ö|Ç/g, (match) => turkishLowerMap[match]);
      return text.toLowerCase();
    },

    filterMenuItems() {
      this.setMenuItemsVisibility(this.menuItems, this.search);
    },

    setMenuItemsVisibility(menuItems, search) {
      const searchLower = this.turkishToLowercase(search);
      const searchTexts = searchLower.split(" ");
      menuItems.forEach((item) => {
        const itemMatches =
          (searchTexts.every((searchText) =>
            this.turkishToLowercase(item.name).includes(searchText)
          ) ||
            searchTexts.every((searchText) =>
              this.turkishToLowercase(item.keywords).includes(searchText)
            )) &&
          (item.searchable || !searchLower);

        if (itemMatches) {
          item.visible = true;
        } else {
          item.visible = false;
        }

        if (item.subMenuItems) {
          this.setMenuItemsVisibility(item.subMenuItems, search);
          if (item.subMenuItems.some((subItem) => subItem.visible)) {
            item.visible = true;
          }
        }
      });
    },

    menuItemExists(targetUrl) {
      const stack = [...this.menuItems];

      while (stack.length) {
        const item = stack.pop();

        if (item.url === targetUrl && item.visible) {
          return true;
        }

        if (item.subMenuItems) {
          stack.push(...item.subMenuItems);
        }
      }
      return false;
    },

    isMenuItemVisible(url) {
      return this.menuItemExists(url);
    },
  },
  created() {
    this.filteredMenuItems = this.menuItems;
  },
};
</script>

<style lang="scss">
.v-icon.payment-warning {
  background-color: #a52a2a !important;
  border-radius: 50%;
  font-size: 20px !important;
  margin: 10px;
  margin-left: 0;
  position: absolute !important;
  right: 40px;

  .menu-toggle & {
    margin: 4px;
    margin-right: 10px;
    margin-left: 0;
  }
}
</style>
